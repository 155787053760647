import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, FullIntakeQuestion } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  isEphemeral: yup.string().required('Please select an option'),
});

function Ephemeral({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      'Do you want me to use Ephemeral (temporary) ink for your tattoo?',
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  const formik = useFormik({
    initialValues: {
      isEphemeral: formValues.isEphemeral,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('isEphemeral', values.isEphemeral!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="is-ephemeral">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />

      <Box className="input-container">
        <RadioGroup
          name="isEphemeral"
          value={formik.values.isEphemeral}
          onChange={formik.handleChange}
          className="radio-box">
          <FormControlLabel
            value="true"
            control={<Radio className="radio-input" color="secondary" />}
            label="Yes"
            className={`radio-pill ${
              formik.values.isEphemeral === 'true' ? 'checked' : ''
            }`}
          />
          <FormControlLabel
            value="false"
            control={<Radio className="radio-input" color="secondary" />}
            label="No"
            className={`radio-pill ${
              formik.values.isEphemeral === 'false' ? 'checked' : ''
            }`}
          />
        </RadioGroup>
        {formik.touched.isEphemeral && formik.errors.isEphemeral && (
          <FormHelperText className="error">
            {formik.errors.isEphemeral}
          </FormHelperText>
        )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Ephemeral;
