import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import { FormValues, FullIntakeQuestion } from '../../interfaces';
import BottomActionBar from '../Global/BottomActionBar';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  firstTattoo: yup.string().required('Please select an option'),
});

function FirstTattoo({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      `Hi ${formValues.firstName}! Is this your first tattoo?`,
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  const formik = useFormik({
    initialValues: {
      firstTattoo: formValues.firstTattoo,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('firstTattoo', values.firstTattoo!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="first-tattoo">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />

      <Box className="input-container">
        <RadioGroup
          aria-labelledby="radio-firstTattoo"
          name="firstTattoo"
          id="first-tattoo-radio"
          value={formik.values.firstTattoo}
          onChange={formik.handleChange}
          className="radio-box">
          <FormControlLabel
            value="true"
            control={
              <Radio
                className="radio-input"
                color="secondary"
                inputProps={{ className: 'radio-first-tattoo' }}
              />
            }
            label="Yes"
            className={`radio-pill ${
              formik.values.firstTattoo === 'true' ? 'checked' : ''
            }`}
          />
          <FormControlLabel
            value="false"
            control={
              <Radio
                className="radio-input"
                color="secondary"
                inputProps={{ className: 'radio-first-tattoo' }}
              />
            }
            label="No"
            className={`radio-pill ${
              formik.values.firstTattoo === 'false' ? 'checked' : ''
            }`}
          />
        </RadioGroup>
        {formik.touched.firstTattoo && formik.errors.firstTattoo && (
          <FormHelperText className="error">
            {formik.errors.firstTattoo}
          </FormHelperText>
        )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default FirstTattoo;
