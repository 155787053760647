import { Roles, Users } from '@prisma/client';
import { OnSchedCommonType } from '../types/onsched';

export const isUserEmployeeOrAdmin = (user: Users | Roles) => {
  const role = typeof user === 'string' ? user : user?.role;
  return (
    [Roles.EMPLOYEE, Roles.SHOP_MANAGER, Roles.ADMIN] as Roles[]
  ).includes(role);
};

export const isUserEmployee = (user: Users | Roles) => {
  const role = typeof user === 'string' ? user : user?.role;
  return ([Roles.EMPLOYEE, Roles.SHOP_MANAGER] as Roles[]).includes(role);
};

export function getMinutesBetweenOnschedHours(start: number, end: number) {
  const startHours = Math.floor(start / 100);
  const startMinutes = start % 100;
  const endHours = Math.floor(end / 100);
  const endMinutes = end % 100;

  const startTotal = startHours * 60 + startMinutes;
  const endTotal = endHours * 60 + endMinutes;

  return endTotal - startTotal;
}

type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export function getEmployeeMinutesDurationByDay(
  availability: OnSchedCommonType.Availability | undefined,
  selectedDay: Date,
) {
  if (!availability)
    return { startTime: 0, endTime: 0, openDurationInMinutes: 0 };

  const dayMap = [
    'sun',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
  ] as DayOfWeek[];

  const key = dayMap[selectedDay.getDay()];

  const daysAvailability = availability[key];
  const { startTime, endTime } = daysAvailability;
  return {
    startTime,
    endTime,
    openDurationInMinutes: getMinutesBetweenOnschedHours(startTime, endTime),
  };
}

export function timeIsInFirstHalfOfDay({
  timeToCheck,
  openTime,
  closeTime,
}: {
  timeToCheck: number;
  openTime: number;
  closeTime: number;
}) {
  const midpoint = (openTime + closeTime) / 2;
  return timeToCheck < midpoint;
}
