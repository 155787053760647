/* eslint-disable no-nested-ternary */
import { Stack, Typography, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ReturnIcon from '@mui/icons-material/Replay';
import PendingIcon from '@mui/icons-material/Pending';
import { PaymentReceipt } from '../../types/checkout';

interface Props {
  receipt: PaymentReceipt;
}

function PaymentReceiptStatus({ receipt }: Props) {
  const theme = useTheme();

  const Icon =
    receipt.status === 'FAILED'
      ? ErrorIcon
      : receipt.status === 'SUCCESS'
      ? CheckCircleIcon
      : receipt.status === 'REFUNDED'
      ? ReturnIcon
      : PendingIcon;
  const iconColor =
    receipt.status === 'FAILED'
      ? theme.palette.error.main
      : receipt.status === 'SUCCESS'
      ? theme.palette.success.main
      : theme.palette.medGrey.main;
  const statusText =
    receipt.status === 'FAILED'
      ? 'Payment Failed'
      : receipt.status === 'SUCCESS'
      ? 'Payment Successful'
      : receipt.status === 'REFUNDED'
      ? 'Payment Refunded'
      : 'Payment Pending';
  const description =
    receipt.status === 'PENDING'
      ? 'If you recently made a payment, it may take a few minutes to process. Refresh the page to check the status.'
      : '';

  return (
    <Stack justifyContent="center" alignItems="center" gap={1}>
      <Icon sx={{ color: iconColor, fontSize: 50 }} />
      <Typography variant="h2" component="p" color="black">
        {statusText}
      </Typography>
      {!!description && (
        <Typography
          textAlign="center"
          variant="body2"
          component="p"
          color={theme.palette.medGrey.main}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}

export default PaymentReceiptStatus;
