import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { Checkbox, FormControlLabel } from '@mui/material';
import BottomActionBar from '../Global/BottomActionBar';
import {
  Business,
  Employee,
  FormValues,
  FullIntakeQuestion,
} from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  employee: Employee | undefined;
  business: Business | undefined;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  details: yup.string().required('Tattoo details are required'),
});

function TattooDetails({
  formValues,
  employee,
  business,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const [oldDetails, setOldDetails] = React.useState('');

  const entityName = employee
    ? employee?.displayName ||
      `${employee?.user.firstName} ${employee?.user.lastName}`.trim()
    : business?.name || 'the studio';

  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle || 'Describe your tattoo in detail',
    subHeader: intakeQuestion?.customQuestionSubheader || '',
    placeholder:
      intakeQuestion?.customQuestionPlaceholder ||
      `Tell ${entityName} about the work you want done. You can be as detailed as you want. If you don't know exactly what you're looking for, that's fine too! Just let ${entityName} know.`,
  };

  const formik = useFormik({
    initialValues: {
      details: formValues.details,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('details', values.details!);
      nextStep();
    },
  });

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setOldDetails(formik.values.details || '');
      formik.setFieldValue(
        'details',
        "I don't know exactly what I want yet. I'd like to talk with you to figure it out. Feel free to start a chat with me using the app and we can work out the details",
      );
    } else {
      formik.setFieldValue('details', oldDetails);
    }
  };

  const businessId =
    business?.businessId || employee?.business?.businessId || '';
  const businessIdsNoCheckbox = [
    'cm47ffwzl004lrruh8zips51w',
    'cm51byiny0043ntqghi8j4rwm',
  ];

  return (
    <form onSubmit={formik.handleSubmit} id="tattoo-details">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        <TextField
          id="details"
          name="details"
          value={formik.values.details}
          onChange={formik.handleChange}
          error={formik.touched.details && Boolean(formik.errors.details)}
          helperText={formik.touched.details && formik.errors.details}
          multiline
          placeholder={questionValues.placeholder}
          minRows={3}
          className="textarea"
        />
        {employee?.employeeId !== 'clp1tilea0000s60u1xlhc0jc' &&
          !businessIdsNoCheckbox.includes(businessId) && (
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label="I don't know what I want"
              onChange={handleCheckboxChange}
              style={{ marginTop: -20 }}
            />
          )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default TattooDetails;
