import {
  Box,
  capitalize,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import useGetPaymentReceipt from '../hooks/payments/useGetPaymentReceipt';
import useUser from '../hooks/global/useUser';
import Login from '../components/auth/Login';
import PaymentReceiptStatus from '../components/payments/PaymentReceiptStatus';
import PaymentReceiptLineItem from '../components/payments/PaymentReceiptLineItem';
import { convertSnakeToTitleCase } from '../utils/global';

export default function ReceiptPage() {
  const { paymentId } = useParams();
  const { user, isLoading: isAuthLoading } = useUser();

  const {
    data: receipt,
    isLoading,
    isError,
  } = useGetPaymentReceipt({
    paymentId,
  });

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar title="Receipt" subtitle="Receipt for your payment" />
      {(isLoading || isAuthLoading) && (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {!!user && (
        <>
          {receipt && (
            <Stack mt={10} px={2} gap={2}>
              <PaymentReceiptStatus receipt={receipt} />
              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: theme => theme.palette.lightGrey.main,
                }}
              />

              <PaymentReceiptLineItem
                label="Payment Method"
                text={convertSnakeToTitleCase(receipt.paymentMethod)}
              />

              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: theme => theme.palette.lightGrey.main,
                }}
              />

              <Stack gap={1}>
                {!!receipt?.appointmentServiceCategory && (
                  <PaymentReceiptLineItem
                    label={capitalize(
                      receipt.appointmentServiceCategory.toLowerCase(),
                    )}
                    amount={receipt.servicePriceCents / 100}
                  />
                )}
                {receipt.orderItems.map((orderItem, idx) => (
                  <PaymentReceiptLineItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    label={`${orderItem.name}${
                      orderItem.quantity > 1 ? ` (${orderItem.quantity})` : ''
                    }`}
                    amount={orderItem.price * orderItem.quantity}
                  />
                ))}

                {receipt.tipAmountCents > 0 && (
                  <PaymentReceiptLineItem
                    label="Tip"
                    amount={receipt.tipAmountCents / 100}
                  />
                )}
                {receipt.discountAmountCents > 0 && (
                  <PaymentReceiptLineItem
                    label="Discount"
                    amount={receipt.discountAmountCents / 100}
                    subtract
                  />
                )}
                {(receipt.salesTaxCents > 0 || receipt.serviceFeeCents > 0) && (
                  <PaymentReceiptLineItem
                    label="Taxes & Fees"
                    amount={
                      (receipt.salesTaxCents + receipt.serviceFeeCents) / 100
                    }
                  />
                )}
                {receipt.giftCardAmountCents > 0 && (
                  <PaymentReceiptLineItem
                    label="Gift Card"
                    amount={receipt.giftCardAmountCents / 100}
                    subtract
                  />
                )}
              </Stack>

              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: theme => theme.palette.lightGrey.main,
                }}
              />
              <PaymentReceiptLineItem
                label="Total"
                amount={receipt.totalAmountCents / 100}
                isTotal
              />
            </Stack>
          )}
          {isError && <p>There was an error loading the receipt</p>}
        </>
      )}
      {!isAuthLoading && !user && <Login />}
      <PorterFooter />
    </Box>
  );
}
