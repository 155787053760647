import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, Choice, FullIntakeQuestion } from '../../interfaces';
import { getChoices } from '../../services/choices';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  skinTone: yup.string().required('Please select an option'),
});

function SkinTone({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const [skinTones, setSkinTones] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  const questionValues = {
    header: intakeQuestion?.customQuestionTitle || "What's your skin tone?",
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  React.useEffect(() => {
    async function fetchData() {
      const tempSkinTones = await getChoices('SKIN_TONE').catch(() => {});
      tempSkinTones.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setSkinTones(tempSkinTones);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      skinTone: formValues.skinTone,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('skinTone', values.skinTone!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="skin-tone">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        {loading ? <Typography variant="inherit">Loading...</Typography> : null}
        {!loading && !skinTones ? (
          <Typography variant="inherit">
            Error loading tattoo locations
          </Typography>
        ) : null}
        {skinTones && skinTones.length > 0 ? (
          <TextField
            id="skinTone"
            select
            name="skinTone"
            label="Skin Tone"
            value={formik.values.skinTone}
            onChange={formik.handleChange}
            error={formik.touched.skinTone && Boolean(formik.errors.skinTone)}
            helperText={formik.touched.skinTone && formik.errors.skinTone}>
            {skinTones.map(skinTone => (
              <MenuItem
                key={skinTone.name}
                value={skinTone.name}
                className="skin-tone-option">
                {skinTone.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default SkinTone;
