import * as React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import {
  FormValues,
  Employee,
  Business,
  FullIntakeQuestion,
} from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  employee: Employee | undefined;
  business: Business | undefined;
  nextStep: () => void;
  prevStep: () => void;
  backwards: boolean;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  newCustomer: yup.string().required('Please select an option'),
});

function NewCustomer({
  formValues,
  setFormValues,
  employee,
  business,
  nextStep,
  prevStep,
  backwards,
  intakeQuestion,
}: Props) {
  let entityName = '';
  if (employee) {
    entityName =
      employee?.displayName ||
      `${employee?.user.firstName} ${employee?.user.lastName}`;
  } else if (business) {
    entityName = business.name;
  }

  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      `Have you gotten a tattoo from ${entityName} before?`,
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  const formik = useFormik({
    initialValues: {
      newCustomer: formValues.newCustomer,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('newCustomer', values.newCustomer!);
      nextStep();
    },
  });

  React.useEffect(() => {
    if (formValues.firstTattoo === 'true') {
      setFormValues({
        ...formValues,
        newCustomer: 'true',
      });
      if (backwards) {
        prevStep();
      } else {
        nextStep();
      }
    }
  }, [backwards, formValues, nextStep, prevStep, setFormValues]);

  return (
    <form onSubmit={formik.handleSubmit} id="new-customer">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />

      <Box className="input-container">
        <RadioGroup
          aria-labelledby="radio-newCustomer"
          id="new-customer-radio"
          name="newCustomer"
          value={formik.values.newCustomer}
          onChange={formik.handleChange}
          className="radio-box">
          <FormControlLabel
            value="false"
            control={
              <Radio
                className="radio-input"
                color="secondary"
                inputProps={{ className: 'radio-new-customer' }}
              />
            }
            label="Yes"
            className={`radio-pill ${
              formik.values.newCustomer === 'false' ? 'checked' : ''
            }`}
          />
          <FormControlLabel
            value="true"
            control={
              <Radio
                className="radio-input"
                color="secondary"
                inputProps={{ className: 'radio-new-customer' }}
              />
            }
            label="No"
            className={`radio-pill ${
              formik.values.newCustomer === 'true' ? 'checked' : ''
            }`}
          />
        </RadioGroup>
        {formik.touched.newCustomer && formik.errors.newCustomer && (
          <FormHelperText className="error">
            {formik.errors.newCustomer}
          </FormHelperText>
        )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default NewCustomer;
