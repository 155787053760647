import API_URL from '../apiconfig';
import axios from './api';

export async function validateDiscountCode({
  discountCode,
  appointmentId,
  orderId,
}: {
  discountCode: string;
  appointmentId: string | undefined;
  orderId: string | undefined;
}) {
  try {
    const response = await axios.get(
      `${API_URL}/v1/discounts/validate/${discountCode}`,
      {
        params: {
          appointmentId,
          orderId,
        },
      },
    );

    return !!response.data?.result?.isValid;
  } catch (error) {
    throw new Error('Cannot validate discount code');
  }
}
