import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input';
import {
  BusinessToQuestionsWithQuestion,
  EmployeeToQuestionsWithQuestion,
  FullIntakeQuestion,
} from '../interfaces';

export function important<T>(value: T): T {
  return `${value} !important` as any;
}

export function formatPhone(phoneNumber: string) {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  const formattedPhoneNumber = (
    parsedPhoneNumber?.countryCallingCode === '1'
      ? formatPhoneNumber
      : formatPhoneNumberIntl
  )(phoneNumber);

  return formattedPhoneNumber;
}

export function isNil<T>(
  value: T | null | undefined,
): value is null | undefined {
  return value === null || value === undefined;
}

export function sxToObject(sx: SxProps<Theme> | undefined, theme: Theme) {
  return typeof sx === 'function' ? sx(theme) : sx;
}

export const formatDollarAmount = (
  number: number | undefined,
  omitDecimalsIfZero = false,
  omitDollarSign = false,
) => {
  const dollarSign = omitDollarSign ? '' : '$';

  if (!number) {
    return `${dollarSign}0.00`;
  }

  let newNumber = number;

  // Ensure the input is a number
  if (typeof number !== 'number') {
    newNumber = parseInt(number, 10);
  }

  // Convert number to a string with exactly two decimal places
  let formattedNumber = newNumber.toFixed(2);

  // Add commas as thousand separators
  const parts = formattedNumber.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  formattedNumber = parts.join('.');

  if (omitDecimalsIfZero && parts[1] === '00') {
    return `${dollarSign}${parts[0]}`;
  }

  // Add dollar sign
  return `${dollarSign}${formattedNumber}`;
};

export function convertSnakeToTitleCase(snakeCaseStr: string): string {
  return snakeCaseStr
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
}

export function transformIntakeQuestions(
  entityToQuestions:
    | EmployeeToQuestionsWithQuestion[]
    | BusinessToQuestionsWithQuestion[],
): FullIntakeQuestion[] {
  return entityToQuestions.map(({ question, ...rest }) => ({
    ...rest,
    ...question,
  }));
}
