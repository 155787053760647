import { useQuery } from '@tanstack/react-query';
import { getBusinessById } from '../../services/business';
import useUser from '../global/useUser';

function useGetBusinessById(businessId: string | null | undefined) {
  const { user } = useUser();
  const {
    data: business,
    isLoading,
    isFetching,
    isError,
    isPlaceholderData,
    error,
  } = useQuery({
    queryKey: ['business-by-id', businessId],
    queryFn: async () => {
      const tempBusiness = await getBusinessById(businessId!);
      return tempBusiness || null;
    },
    enabled: !!businessId && !!user?.userId,
  });

  return {
    business: businessId ? business : null,
    isLoading,
    isFetching,
    isError,
    isPlaceholderData,
    error,
  };
}

export default useGetBusinessById;
