import { useQuery } from '@tanstack/react-query';
import { getEmployeeByUrlPath, getEmployeeById } from '../../services/employee';
import { Employee } from '../../interfaces';

function useGetEmployee({
  employeeId,
  path,
  onSuccess,
  enabled: enabledProp = true,
}: {
  employeeId?: string;
  path?: string;
  onSuccess?: (employee: Employee) => void;
  enabled?: boolean;
}) {
  const enabled = enabledProp && (!!path || !!employeeId);

  const query = useQuery({
    queryKey: ['employee', path, employeeId],
    queryFn: async () => {
      const getEmployee = path ? getEmployeeByUrlPath : getEmployeeById;

      const tempEmployee = await getEmployee(path ?? employeeId!);

      if (tempEmployee) {
        onSuccess?.(tempEmployee);
      }

      return tempEmployee || null;
    },
    enabled,
  });

  return {
    employee: query.data,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    isError: query.isError,
    isPlaceholderData: query.isPlaceholderData,
  };
}

export default useGetEmployee;
