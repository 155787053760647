import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut as fbSignOut,
} from 'firebase/auth';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { auth } from '../firebase';
import {
  SignInErrorResult,
  SignInResult,
  SignInSuccessResult,
} from '../types/auth';

export const signIn = async (
  phone: string | undefined,
  recaptcha: RecaptchaVerifier,
  callbacks?: {
    onValidPhone?: (phone: string) => void | Promise<void>;
  },
): Promise<SignInResult> => {
  if (!phone || !isPossiblePhoneNumber(phone)) {
    return {
      error: 'Please enter a valid US phone number',
    } as SignInErrorResult;
  }
  await callbacks?.onValidPhone?.(phone);
  const result = await signInWithPhoneNumber(auth, phone, recaptcha);
  return {
    phone,
    recaptcha,
    confirmation: result,
  } as SignInSuccessResult;
};

export const signOut = () => {
  fbSignOut(auth);
  window.location.reload();
};
