import * as React from 'react';
import '../Booking.css';
import Typography from '@mui/material/Typography';
import { Box, capitalize, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import PaymentMethods from '../Checkout/PaymentMethod';
import { closeCheckout } from '../../../services/appointment';
import LoadingModal from '../../Global/LoadingModal';
import AddNewPaymentMethod from '../Checkout/AddNewPaymentMethod';
import useCheckoutSession from '../../../hooks/payments/useCheckoutSession';
import useDebouncedState from '../../../hooks/useDebouncedState';
import usePaymentMethods from '../../../hooks/payments/usePaymentMethods';
import STRIPE_PK from '../../../stripe';
import SubmitButton from '../Checkout/SubmitButton';
import FormSmallModal, { FormData } from '../../Global/FormSmallModal';
import {
  discountCodeSchema,
  DiscountCodeValues,
} from '../../../types/discounts';
import { validateDiscountCode } from '../../../services/discounts';
import LoadingIndicator from '../../Global/LoadingIndicator';
import { validateCheckout } from '../../../services/payments';
import { formatDollarAmount } from '../../../utils/global';
import ColoredAlert from '../../Global/ColoredAlert';
import { signOut } from '../../../utils/auth';

type Tip = 15 | 20 | 25 | 'custom';

const DEFAULT_TIP = 0.2;

const stripePromise = loadStripe(STRIPE_PK);

const getInitialTip = ({
  appointmentPriceCents,
  depositPaidCents,
  orderPriceCents,
}: {
  appointmentPriceCents: number;
  depositPaidCents: number;
  orderPriceCents: number;
}) =>
  Math.round(
    (appointmentPriceCents + depositPaidCents + orderPriceCents) * DEFAULT_TIP,
  );

function Checkout() {
  const { paymentId } = useParams();
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointment') ?? undefined;
  const orderId = searchParams.get('order') ?? undefined;

  const [activeTip, setActiveTip] = React.useState<Tip>(20);
  const [tipAmtCents, debouncedTipCents, setTipAmtCents] = useDebouncedState(
    0,
    activeTip === 'custom' ? 500 : 0,
  );
  const [isInitialLoaded, setIsInitialLoaded] = React.useState(false);
  const [isTipDisabled, setIsTipDisabled] = React.useState(false);
  const [form, setForm] = React.useState<FormData | null>(null);
  const [discountCode, setDiscountCode] = React.useState<string | null>(null);

  const {
    checkoutSession,
    isFetching,
    error,
    isError,
    isLoading: isLoadingSession,
  } = useCheckoutSession({
    appointmentId,
    orderId,
    paymentId,
    tipAmountCents: isTipDisabled ? 0 : debouncedTipCents || 0,
    discountCode,
    onSuccess: ({
      appointmentPriceCents,
      orderPriceCents,
      depositPaidCents,
      business,
      appointment,
      order,
      employee,
    }) => {
      if (!isInitialLoaded) {
        const isTipDisabledTemp =
          !!business?.disableCreditTipping || (!!order && !employee);
        if (!isTipDisabled && (appointment || order))
          setTipAmtCents(
            getInitialTip({
              appointmentPriceCents,
              depositPaidCents,
              orderPriceCents,
            }),
          );
        setIsTipDisabled(isTipDisabledTemp);
        setIsInitialLoaded(true);
      }
    },
  });
  const {
    appointmentPriceCents = 0,
    orderPriceCents = 0,
    depositCents = 0,
    depositPaidCents = 0,
    isDepositPaid,
    salesTaxCents = 0,
    serviceFeeCents = 0,
    totalPriceCents = 0,
    discountAmountCents = 0,
    paymentIntent,
    appointment,
    order,
    business,
    employee,
    orderItems = [],
  } = checkoutSession || {};

  const isPermissionsError = (error as AxiosError)?.response?.status === 403;

  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const [needsPaymentInfo, setNeedsPaymentInfo] = React.useState(false);
  const { paymentMethods, isFetching: isFetchingPaymentMethods } =
    usePaymentMethods({
      onSuccess: pms => {
        setNeedsPaymentInfo(!pms.length);
      },
    });
  const paymentMethod = paymentMethods?.[0];

  const isLoading =
    isFetching || debouncedTipCents !== tipAmtCents || isFetchingPaymentMethods;
  const isCheckoutDisabled =
    isLoading ||
    !totalPriceCents ||
    submitting ||
    isError ||
    !checkoutSession?.paymentIntent?.client_secret;

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Processing payment...';
  const successMessage = 'Payment completed!';
  const entityName =
    business?.type === 'STUDIO'
      ? business.name
      : employee?.displayName || business?.name;
  const tipReceiverName =
    employee || business?.type === 'INDEPENDENT'
      ? employee?.displayName || business?.name
      : business?.name;
  const profileImage =
    business?.type === 'STUDIO'
      ? business?.thumbnailImage
      : employee?.profileImage;

  const changeTip = (percent: Tip) => {
    if (isTipDisabled) return;

    const multiplier = typeof percent === 'number' ? percent / 100 : 0;
    const newTipAmount = Math.round(
      (appointmentPriceCents + depositPaidCents + orderPriceCents) * multiplier,
    );
    setActiveTip(percent);

    setTipAmtCents(Math.max(0, newTipAmount));
  };

  const processPayment = React.useCallback(
    async (confirmPayment?: () => Promise<PaymentIntentResult>) => {
      const stripe = await stripePromise;

      if (
        isLoading ||
        isError ||
        !stripe ||
        !paymentIntent?.client_secret ||
        !checkoutSession ||
        (!appointment?.appointmentId && !order?.orderId) ||
        isCheckoutDisabled ||
        (needsPaymentInfo && !confirmPayment)
      )
        return;

      setSubmitting(true);
      setErrorMessage('');

      const isValid = await validateCheckout({
        appointmentId: appointment?.appointmentId,
        orderId: order?.orderId,
        previousCheckoutSession: checkoutSession,
        tipAmountCents: isTipDisabled ? 0 : tipAmtCents,
        discountCode,
      });
      if (!isValid) {
        setSubmitting(false);
        setErrorMessage(
          'This payment is no longer available for checkout. Please refresh the page and try again.',
        );
        return;
      }

      const result = confirmPayment
        ? await confirmPayment()
        : await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethod?.id,
          });
      if (result.error) {
        setSubmitting(false);
        setErrorMessage(
          result.error.message ||
            'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
        );
        return;
      }

      if (result.paymentIntent?.next_action?.redirect_to_url?.url) {
        window.location.replace(
          result.paymentIntent.next_action.redirect_to_url.url?.toString(),
        );
      }

      if (paymentIntent) {
        setSuccess(true);

        if (appointment) {
          const totalPrice =
            Math.max(
              0,
              appointmentPriceCents + orderPriceCents - discountAmountCents,
            ) / 100;
          const tipPrice = tipAmtCents / 100;
          await closeCheckout(
            appointment.appointmentId,
            checkoutSession.paymentId,
            totalPrice,
            tipPrice,
          ).then(() => {
            setTimeout(() => {
              window.location.replace(`/booking/${appointment.appointmentId}`);
            }, 2000);
          });
        } else if (order) {
          setTimeout(() => {
            window.location.replace(`/receipt/${checkoutSession.paymentId}`);
          }, 2000);
        }
      }
    },
    [
      isLoading,
      isError,
      paymentIntent,
      checkoutSession,
      appointment,
      order,
      isCheckoutDisabled,
      needsPaymentInfo,
      isTipDisabled,
      tipAmtCents,
      discountCode,
      paymentMethod?.id,
      appointmentPriceCents,
      orderPriceCents,
      discountAmountCents,
    ],
  );

  const discountForm: FormData<DiscountCodeValues> = {
    initialValues: {
      discountCode: '',
    },
    inputRows: [
      [
        {
          inputType: 'text',
          label: '',
          name: 'discountCode',
          placeholder: 'Enter discount code',
        },
      ],
    ],
    onSubmit: async values => {
      try {
        if (!appointment?.appointmentId && !order?.orderId) return;
        const isValid = await validateDiscountCode({
          discountCode: values.discountCode,
          appointmentId: appointment?.appointmentId,
          orderId: order?.orderId,
        });
        if (!isValid) throw new Error('Invalid discount code');
        setDiscountCode(values.discountCode);
        setForm(null);
      } catch {
        toast.error('The discount code you entered is invalid.');
      }
    },
    submitButtonText: 'Apply',
    title: 'Apply Discount Code',
    validationSchema: discountCodeSchema,
  };

  if (isLoadingSession) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Box
        style={{
          flex: '1 1 0',
          marginTop: 63,
        }}>
        <div className="checkout-flex">
          <div className="checkout-header">
            <Avatar alt={entityName} src={profileImage || ''} />
            <Typography variant="h3">{entityName}</Typography>
            {business && (
              <Typography variant="body1">{business.city}</Typography>
            )}
          </div>
          <div className="checkout-table">
            {isError && (
              <ColoredAlert
                type="error"
                animate={false}
                title="Failed to load checkout"
                subtitle={`Please refresh the page and try again${
                  isPermissionsError
                    ? '. You may be logged in with a different account than the one you used to create the booking. Try logging out, and make sure to log back in with the same phone number you used to create the booking.'
                    : ''
                }`}
                dismissible={false}>
                {isPermissionsError && (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ mt: 1, p: 1.5 }}
                    onClick={signOut}>
                    Sign Out
                  </Button>
                )}
              </ColoredAlert>
            )}
            {!!appointment && (
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  {appointment?.tattooLocation
                    ? `${appointment?.tattooLocation} tattoo`
                    : capitalize(appointment.serviceCategory.toLowerCase())}
                </Typography>
                <Typography variant="body2">
                  {formatDollarAmount(appointment?.price || 0)}
                </Typography>
              </div>
            )}
            {orderItems.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="checkout-row" key={idx}>
                <Typography variant="body2" className="grow">
                  {item.name}
                  {item.quantity > 1 ? ` (${item.quantity})` : ''}
                </Typography>
                <Typography variant="body2">
                  {formatDollarAmount((item.price || 0) * item.quantity)}
                </Typography>
              </div>
            ))}
          </div>
          {!isTipDisabled && (
            <div className="checkout-tips">
              <div className="tip-summary">
                <Typography variant="h4">Tip:</Typography>
                <Typography variant="h4">
                  {formatDollarAmount(tipAmtCents / 100)}
                </Typography>
              </div>
              <div className="tip-suggestions">
                <Button
                  className={`tip-box ${activeTip === 15 ? 'active' : null}`}
                  onClick={() => changeTip(15)}>
                  <Typography variant="h4">15%</Typography>
                </Button>
                <Button
                  className={`tip-box ${activeTip === 20 ? 'active' : null}`}
                  onClick={() => changeTip(20)}>
                  <Typography variant="h4">20%</Typography>
                </Button>
                <Button
                  className={`tip-box ${activeTip === 25 ? 'active' : null}`}
                  onClick={() => changeTip(25)}>
                  <Typography variant="h4">25%</Typography>
                </Button>
                <Button
                  className={`tip-box ${
                    activeTip === 'custom' ? 'active' : null
                  }`}
                  onClick={() => changeTip('custom')}>
                  <Typography variant="h4">Other</Typography>
                </Button>
              </div>
              <Typography variant="body2" color="var(--medGrey)">
                100% of your tip goes to {tipReceiverName}
              </Typography>
              {activeTip === 'custom' && (
                <NumericFormat
                  placeholder="Tip Amount"
                  name="tip"
                  className="tip-input"
                  thousandSeparator=","
                  allowNegative={false}
                  customInput={TextField}
                  decimalScale={2}
                  value={tipAmtCents / 100}
                  prefix="$"
                  onValueChange={(values: any) => {
                    if (Number(values.value)) {
                      setTipAmtCents(parseInt(values.value, 10) * 100);
                      return;
                    }
                    setTipAmtCents(0);
                  }}
                />
              )}
            </div>
          )}
          <div className="checkout-table">
            <div className="checkout-row">
              <Typography variant="body2" className="grow">
                Subtotal
              </Typography>
              <Typography variant="body2">
                {formatDollarAmount(
                  (appointmentPriceCents + orderPriceCents + depositPaidCents) /
                    100,
                )}
              </Typography>
            </div>
            {!isTipDisabled && (
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  Tip
                </Typography>
                <Typography variant="body2">
                  {formatDollarAmount(tipAmtCents / 100)}
                </Typography>
              </div>
            )}
            {(salesTaxCents > 0 || serviceFeeCents > 0) && (
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  {salesTaxCents ? 'Taxes & Fees' : 'Service Fee'}
                </Typography>
                <Typography variant="body2">
                  {isFetching
                    ? '-'
                    : formatDollarAmount(
                        (salesTaxCents + serviceFeeCents) / 100,
                      )}
                </Typography>
              </div>
            )}
            {isDepositPaid && (
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  Deposit paid
                </Typography>
                <Typography variant="body2" color="red">
                  -{formatDollarAmount(depositCents / 100)}
                </Typography>
              </div>
            )}
            <div className="checkout-row">
              {!discountCode && (
                <Button
                  disableRipple
                  className="discount-button"
                  onClick={() => setForm(discountForm)}
                  sx={{
                    padding: 0,
                    minWidth: 0,
                    fontSize: theme => theme.typography.body2.fontSize,
                    fontWeight: 400,
                    color: '#007AFF',
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                  }}>
                  Add discount code
                </Button>
              )}
              {discountCode && (
                <>
                  <Typography variant="body2" className="grow">
                    Discount ({discountCode})
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Button
                      onClick={() => setDiscountCode(null)}
                      sx={{
                        minWidth: 0,
                        padding: 0,
                      }}>
                      <DeleteIcon
                        fontSize="small"
                        sx={{
                          color: theme => theme.palette.medGrey.main,
                        }}
                      />
                    </Button>
                    <Typography variant="body2" color="red">
                      {isFetching
                        ? '-'
                        : `-${formatDollarAmount(discountAmountCents / 100)}`}
                    </Typography>
                  </Box>
                </>
              )}
            </div>
            <div className="checkout-row">
              <Typography variant="h4" className="grow">
                TOTAL DUE
              </Typography>
              <Typography variant="h4">
                {isFetching ? (
                  <CircularProgress size={10} />
                ) : (
                  formatDollarAmount(totalPriceCents / 100)
                )}
              </Typography>
            </div>
          </div>
          {errorMessage ? (
            <Typography variant="body1" className="error">
              {errorMessage}
            </Typography>
          ) : null}
          {!needsPaymentInfo && !isError && (
            <PaymentMethods
              paymentMethod={paymentMethod}
              setNeedsPaymentMethod={setNeedsPaymentInfo}
            />
          )}
          {needsPaymentInfo && !isError && (
            <AddNewPaymentMethod
              checkoutSession={checkoutSession ?? null}
              onSubmit={processPayment}
              isDisabled={isCheckoutDisabled}
            />
          )}
        </div>
        {!needsPaymentInfo && !isLoadingSession && !isError && (
          <div className="footer-sticky">
            <SubmitButton
              loading={submitting}
              disabled={!paymentMethod || isCheckoutDisabled}
              onSubmit={() => processPayment()}>
              Pay {formatDollarAmount(totalPriceCents / 100)}
            </SubmitButton>
            <Typography variant="body2" className="center">
              Powered by Porter
            </Typography>
          </div>
        )}
        {submitting && (
          <LoadingModal
            header={modalHeader}
            message={modalMessage}
            open={submitting}
            success={success}
            successMessage={successMessage}
          />
        )}
      </Box>
      <FormSmallModal form={form} onClose={() => setForm(null)} open={!!form} />
    </>
  );
}

export default Checkout;
