/* eslint-disable react/require-default-props */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Business,
  Employee,
  FormValues,
  FullIntakeQuestion,
} from '../../interfaces';
import BottomActionBar from '../Global/BottomActionBar';
import AppointmentCity from './AppointmentCity';
import EmployeeHeader from '../EmployeeHeader/EmployeeHeader';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  appointmentCity?: string;
  setAppointmentCity?: React.Dispatch<React.SetStateAction<string>>;
  employee: Employee | undefined;
  setAppointmentTimezone?: React.Dispatch<React.SetStateAction<string>>;
  askCity?: boolean;
  backwards: boolean;
  clickedEdit: boolean;
  nameStep: number;
  setNameStep: React.Dispatch<React.SetStateAction<number>>;
  business: Business | undefined;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

function Name({
  formValues,
  setFormValues,
  nextStep,
  appointmentCity,
  setAppointmentCity,
  employee,
  setAppointmentTimezone,
  askCity,
  backwards,
  clickedEdit,
  nameStep,
  setNameStep,
  business,
  intakeQuestion,
}: Props) {
  const [submit, setSubmit] = React.useState(false);

  const questionValues = {
    header: intakeQuestion?.customQuestionTitle || "Let's book your tattoo!",
    subHeader:
      intakeQuestion?.customQuestionSubheader ||
      "To get started, what's your name?",
  };

  const formik = useFormik({
    initialValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('firstName', values.firstName!);
      sessionStorage.setItem('lastName', values.lastName!);
      nextStep();
    },
  });

  React.useEffect(() => {
    if (askCity && appointmentCity && submit) {
      formik.handleSubmit();
    }
  }, [appointmentCity, askCity, formik, submit]);

  React.useEffect(() => {
    if (backwards && askCity) {
      setNameStep(2);
    }
  }, [askCity, backwards, setNameStep]);

  const openCityQuestion = () => {
    setNameStep(2);
    window.scrollTo(0, 0);
  };

  switch (nameStep) {
    case 1:
      return (
        <form
          onSubmit={formik.handleSubmit}
          id="name"
          style={{
            paddingTop: 40,
          }}>
          <EmployeeHeader
            employee={employee}
            business={employee?.business ?? business}
          />

          <FormHeader
            header={questionValues.header}
            subHeader={questionValues.subHeader}
          />

          <Box className="input-container">
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <Typography variant="body2" style={{ textAlign: 'left' }}>
              Looking for a request you already submitted?{' '}
              <a href="/booking/" target="_blank">
                Click Here.
              </a>
            </Typography>
          </Box>
          {askCity && !clickedEdit ? (
            <BottomActionBar
              primaryText="Continue"
              primaryDisabled={!formik.isValid}
              primaryAction={openCityQuestion}
            />
          ) : (
            <BottomActionBar
              primaryText="Continue"
              primaryDisabled={!formik.isValid}
              isSubmit
            />
          )}
        </form>
      );

    case 2:
      return (
        <Box
          style={{
            paddingTop: 40,
          }}>
          <EmployeeHeader
            employee={employee}
            business={employee?.business ?? business}
          />
          <AppointmentCity
            employee={employee}
            appointmentCity={appointmentCity || ''}
            setAppointmentCity={setAppointmentCity}
            setAppointmentTimezone={setAppointmentTimezone}
            setNameStep={setNameStep}
            setSubmit={setSubmit}
            setFieldValue={formik.setFieldValue}
          />
        </Box>
      );

    default:
      return (
        <form onSubmit={formik.handleSubmit} id="name">
          <FormHeader
            header={questionValues.header}
            subHeader={questionValues.subHeader}
          />

          <Box className="input-container">
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <Typography variant="body2" style={{ textAlign: 'left' }}>
              Looking for a request you already submitted?{' '}
              <a href="/booking/" target="_blank">
                Click Here.
              </a>
            </Typography>
          </Box>
          {askCity ? (
            <BottomActionBar
              primaryText="Continue"
              primaryDisabled={!formik.isValid}
              primaryAction={openCityQuestion}
            />
          ) : (
            <BottomActionBar
              primaryText="Continue"
              primaryDisabled={!formik.isValid}
              isSubmit
            />
          )}
        </form>
      );
  }
}

export default Name;
