import { ServiceCategory } from '@prisma/client';

export enum BookingSteps {
  PIERCING_LOCATION = 'PIERCING_LOCATION',
  PROVIDER = 'PROVIDER',
  GUESTS = 'GUESTS',
  CALENDAR = 'CALENDAR',
  YOUR_INFO = 'YOUR_INFO',
  CONFIRM = 'CONFIRM',
}

export const SERVICE_BOOKING_STEPS: Partial<
  Record<ServiceCategory, BookingSteps[]>
> = {
  [ServiceCategory.PIERCING]: [
    BookingSteps.PIERCING_LOCATION,
    BookingSteps.GUESTS,
    BookingSteps.PROVIDER,
    BookingSteps.CALENDAR,
    BookingSteps.YOUR_INFO,
    BookingSteps.CONFIRM,
  ],
  [ServiceCategory.PERMANENT_MAKEUP]: [
    BookingSteps.PROVIDER,
    BookingSteps.CALENDAR,
    BookingSteps.YOUR_INFO,
    BookingSteps.CONFIRM,
  ],
  [ServiceCategory.OTHER]: [
    BookingSteps.GUESTS,
    BookingSteps.PROVIDER,
    BookingSteps.CALENDAR,
    BookingSteps.YOUR_INFO,
    BookingSteps.CONFIRM,
  ],
};

export const DEFAULT_STEPS = [
  BookingSteps.PIERCING_LOCATION,
  BookingSteps.PROVIDER,
  BookingSteps.CALENDAR,
  BookingSteps.YOUR_INFO,
  BookingSteps.CONFIRM,
];

export const BOOKING_STEPS_CATEGORY: Record<BookingSteps, string> = {
  [BookingSteps.PIERCING_LOCATION]: 'Details',
  [BookingSteps.PROVIDER]: 'Details',
  [BookingSteps.GUESTS]: 'Details',
  [BookingSteps.CALENDAR]: 'Schedule',
  [BookingSteps.YOUR_INFO]: 'Your Info',
  [BookingSteps.CONFIRM]: 'Confirm',
};

export const DEFAULT_SERVICE_PRICE = 50;
export const DEFAULT_SERVICE_DURATION = 30;

export const DEFAULT_TATTOO_SERVICE_TYPES = [
  'Regular Tattoo',
  'Bestie / Couple Session',
  'Group Appointment (3+)',
  'Touchup',
  'Multiple Tattoos (one person)',
];
