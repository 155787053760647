import { useQuery } from '@tanstack/react-query';
import useUser from '../global/useUser';
import { getCustomersAppointments } from '../../services/customer';

interface Options {
  enabled?: boolean;
}

function useGetUserAppointments({ enabled = true }: Options = {}) {
  const { user } = useUser();
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
  } = useQuery({
    queryKey: ['user-appointments', user?.userId],
    queryFn: async () => {
      const appointments = await getCustomersAppointments();
      return appointments;
    },
    enabled: !!user?.userId && enabled,
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    isPlaceholderData,
  };
}

export default useGetUserAppointments;
