import React from 'react';
import WarningIcon from '@mui/icons-material/WarningAmberRounded';

import './styles.css';
import { Alert, AlertColor, Collapse, Typography } from '@mui/material';

interface ColoredAlertProps {
  type: AlertColor;
  title?: string;
  subtitle: string;
  dismissible?: boolean;
  animate?: boolean;
  persistentDismissAlertKey?: string;
  children?: React.ReactNode;
}

function ColoredAlert({
  type,
  title,
  subtitle,
  dismissible = true,
  animate = true,
  persistentDismissAlertKey,
  children,
}: ColoredAlertProps) {
  const [hidden, setHidden] = React.useState(
    !!persistentDismissAlertKey &&
      sessionStorage.getItem(persistentDismissAlertKey) === 'true',
  );

  const handleClose = () => {
    if (!dismissible) return;
    setHidden(true);

    if (!persistentDismissAlertKey) return;
    sessionStorage.setItem(persistentDismissAlertKey, 'true');
  };

  if (hidden && !animate) return null;

  return (
    <Collapse in={!hidden}>
      <Alert
        variant="standard"
        className="colored-alert"
        severity={type}
        onClose={dismissible ? handleClose : undefined}
        // only override icon if it's a warning - more rounded icon
        iconMapping={{
          warning: <WarningIcon />,
        }}>
        {title && <Typography variant="h3">{title}</Typography>}
        <Typography
          variant="body2"
          style={{ marginTop: !title ? 0 : '0.25rem' }}>
          {subtitle}
        </Typography>
        {children}
      </Alert>
    </Collapse>
  );
}

export default ColoredAlert;
