import API_URL from '../apiconfig';
import { CheckoutSession, PaymentReceipt } from '../types/checkout';
import axios from './api';

export async function getCheckoutSession({
  appointmentId,
  orderId,
  paymentId,
  tipAmountCents,
  discountCode,
  isPriceOnly,
}: {
  appointmentId?: string;
  orderId?: string;
  paymentId?: string;
  tipAmountCents: number;
  discountCode: string | null | undefined;
  isPriceOnly?: boolean;
}) {
  const params = {
    appointmentId,
    orderId,
    paymentId,
    tipAmountCents: tipAmountCents.toString(),
    discountCode,
    isPriceOnly,
  };
  const response = await axios.get(`${API_URL}/v1/payments/checkout`, {
    params,
  });

  return response.data.result as CheckoutSession;
}

export async function validateCheckout({
  appointmentId,
  orderId,
  previousCheckoutSession,
  discountCode,
  tipAmountCents,
}: {
  appointmentId?: string;
  orderId?: string;
  previousCheckoutSession: CheckoutSession;
  discountCode?: string | null;
  tipAmountCents: number;
}) {
  try {
    const refreshedCheckoutSession = await getCheckoutSession({
      appointmentId,
      orderId,
      tipAmountCents,
      discountCode,
      isPriceOnly: true,
    });

    if (
      !refreshedCheckoutSession ||
      refreshedCheckoutSession.totalPriceCents !==
        previousCheckoutSession.totalPriceCents ||
      refreshedCheckoutSession.paymentMethod !== 'CREDIT'
    )
      return false;

    return true;
  } catch (error) {
    // do not throw error - endpoint throws error if checkout is invalid
    return false;
  }
}

export async function getPaymentReceipt({ paymentId }: { paymentId: string }) {
  try {
    const response = await axios.get(
      `${API_URL}/v1/payments/${paymentId}/receipt`,
    );
    return response.data.result as PaymentReceipt;
  } catch (error) {
    throw new Error('Cannot get payment receipt');
  }
}
