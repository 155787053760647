/* eslint-disable no-nested-ternary */
import { Box, CircularProgress } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import Checkout from '../components/Booking/Flows/Checkout';
import useUser from '../hooks/global/useUser';
import Login from '../components/auth/Login';

export default function CheckoutPage() {
  const { user, isLoading: isAuthLoading } = useUser();

  const [searchParams] = useSearchParams();
  const { paymentId } = useParams();
  const appointmentId = searchParams.get('appointment');
  const orderId = searchParams.get('order');

  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar
        title="Checkout"
        subtitle={`Checkout your ${
          orderId
            ? 'order'
            : appointmentId
            ? 'appointment'
            : paymentId
            ? 'order'
            : ''
        }`}
      />
      {!!user && <Checkout />}
      {isAuthLoading && (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {!user && !isAuthLoading && <Login />}
      <PorterFooter />
    </Box>
  );
}
