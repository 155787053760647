import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, FullIntakeQuestion } from '../../interfaces';
import FormHeader from './FormHeader';
import { DEFAULT_TATTOO_SERVICE_TYPES } from '../../constants/services';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

const validationSchema = yup.object({
  tattooServiceType: yup.string().required('Please select a service type'),
});

function TattooServiceType({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      'Which tattoo service do you want to book?',
    subHeader: intakeQuestion?.customQuestionSubheader || '',
  };

  const formik = useFormik({
    initialValues: {
      tattooServiceType: formValues.tattooServiceType,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('tattooServiceType', values.tattooServiceType!);
      nextStep();
    },
  });

  const services = [...DEFAULT_TATTOO_SERVICE_TYPES];

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />

      <Box className="input-container">
        <TextField
          id="tattoo-service-type"
          select
          name="tattooServiceType"
          label="Tattoo Service Type"
          value={formik.values.tattooServiceType}
          onChange={formik.handleChange}
          error={
            formik.touched.tattooServiceType &&
            Boolean(formik.errors.tattooServiceType)
          }
          helperText={
            formik.touched.tattooServiceType && formik.errors.tattooServiceType
          }>
          {services.map(service => (
            <MenuItem key={service} value={service}>
              {service}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default TattooServiceType;
