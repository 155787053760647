import { addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Appointments } from '@prisma/client';
import { Appointment, DetailItem, Employee, User } from '../interfaces';

export function getPriceText(
  priceType: string,
  min?: number,
  max?: number,
  hourly?: number,
  set?: number,
) {
  let text = '';

  switch (priceType) {
    case 'RANGE':
      text = `$${min?.toLocaleString('en-US')} - $${max?.toLocaleString(
        'en-US',
      )} price range`;
      break;

    case 'HOURLY':
      text = `$${hourly?.toLocaleString('en-US')} per hour`;
      break;

    case 'FIXED':
      text = `$${set?.toLocaleString('en-US')}`;
      break;

    default:
      break;
  }

  return text;
}

export function getLengthText(aptLength: number) {
  const hours = Math.floor(aptLength / 60);
  const minutes = aptLength % 60;

  let text = '';
  if (minutes === 0) {
    text = `${hours} hour appointment`;
  } else if (hours === 0) {
    text = `${minutes} minute appointment`;
  } else {
    text = `${hours} hour and ${minutes} minute appointment`;
  }
  return text;
}

export function getAptStartEnd(
  date: Date,
  aptLength: number,
  timezone: string,
) {
  const startDateFormat = 'eeee, MMMM d, yyyy';
  const startTimeFormat = 'h:mma z';
  const endTimeFormat = 'h:mma';
  const endTime = addMinutes(date, aptLength);

  const text = `${formatInTimeZone(
    date,
    timezone,
    startDateFormat,
  )} at ${formatInTimeZone(
    date,
    timezone,
    startTimeFormat,
  )}. Estimated to end at ${formatInTimeZone(
    endTime,
    timezone,
    endTimeFormat,
  )}`;
  return text;
}

export function createScheduleDetailItems(
  aptDetails: Appointment,
  employee: Employee,
) {
  const tempDetailItems: DetailItem[] = [];

  if (aptDetails.tripCity) {
    tempDetailItems.push({
      label: 'Appointment City',
      values: [aptDetails.tripCity],
      type: 'text',
    });
  }

  if (aptDetails.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'Appointment Length',
      values: [getLengthText(aptDetails.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (aptDetails.priceType) {
    tempDetailItems.push({
      label: 'Price',
      values: [
        getPriceText(
          aptDetails.priceType,
          aptDetails.minPrice,
          aptDetails.maxPrice,
          aptDetails.hourlyPrice,
          aptDetails.price,
        ),
      ],
      type: 'text',
    });
  }

  tempDetailItems.push({
    label: `Message from ${employee.displayName || 'Artist'}`,
    values: [aptDetails.customDesignMessage || employee.standardDesignMessage],
    type: 'text',
  });

  return tempDetailItems;
}

export function createDetailitems(
  aptDetails: Appointment,
  timezone: string,
  employee: Employee,
  aptDate?: Date,
) {
  const tempDetailItems: DetailItem[] = [];

  if (aptDetails.tripCity) {
    tempDetailItems.push({
      label: 'Where',
      values: [aptDetails.tripCity],
      type: 'text',
    });
  }

  if (aptDate && aptDetails.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'When',
      values: [
        getAptStartEnd(
          aptDate,
          aptDetails.appointmentLengthInMinutes,
          timezone,
        ),
      ],
      type: 'text',
    });
  }

  if (aptDetails.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'Appointment Length',
      values: [getLengthText(aptDetails.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (aptDetails.tattooLocation) {
    tempDetailItems.push({
      label: 'Tattoo Area',
      values: [aptDetails.tattooLocation],
      type: 'text',
    });
  }

  if (aptDetails.tattooSize) {
    tempDetailItems.push({
      label: 'Tattoo Size',
      values: [aptDetails.tattooSize],
      type: 'text',
    });
  }

  if (aptDetails.tattooColor) {
    tempDetailItems.push({
      label: 'Tattoo Color',
      values: [aptDetails.tattooColor],
      type: 'text',
    });
  }

  if (aptDetails.tattooDetails) {
    tempDetailItems.push({
      label: 'Tattoo Description',
      values: [aptDetails.tattooDetails],
      type: 'text',
    });
  }

  if (aptDetails.priceType) {
    tempDetailItems.push({
      label: 'Price',
      values: [
        getPriceText(
          aptDetails.priceType,
          aptDetails.minPrice,
          aptDetails.maxPrice,
          aptDetails.hourlyPrice,
          aptDetails.price,
        ),
      ],
      type: 'text',
    });
  }

  if (employee.cancellationPolicyText) {
    tempDetailItems.push({
      label: 'Cancellation Policy',
      values: [employee.cancellationPolicyText],
      type: 'text',
    });
  }

  return tempDetailItems;
}

export function createReviewItems(
  selectedDateTime: Date,
  appointment: Appointment,
  employee: Employee,
) {
  const dateFormat = 'MMMM d, Y @ h:mm aaa z';
  const tempDetailItems: DetailItem[] = [];

  if (appointment.tripCity) {
    tempDetailItems.push({
      label: 'Where',
      values: [appointment.tripCity],
      type: 'text',
    });
  }

  const timezone =
    appointment.timezone || employee.user.timezone || 'US/Pacific';

  tempDetailItems.push({
    label: 'When',
    values: [formatInTimeZone(selectedDateTime, timezone, dateFormat)],
    type: 'text',
  });

  if (appointment.appointmentLengthInMinutes) {
    tempDetailItems.push({
      label: 'Appointment Length',
      values: [getLengthText(appointment.appointmentLengthInMinutes)],
      type: 'text',
    });
  }

  if (appointment.tattooLocation) {
    tempDetailItems.push({
      label: 'Tattoo Body Area',
      values: [appointment.tattooLocation],
      type: 'text',
    });
  }

  if (appointment.tattooSize) {
    tempDetailItems.push({
      label: 'Tattoo Size',
      values: [appointment.tattooSize],
      type: 'text',
    });
  }

  tempDetailItems.push({
    label: 'Cancellation Policy',
    values: [employee.cancellationPolicyText],
    type: 'text',
  });

  return tempDetailItems;
}

export function createRequestOverview(
  user: User,
  appointment: Appointment,
  tempSizeOptions?: any,
) {
  const tempDetailItems: DetailItem[] = [];

  if (appointment.rejectionMessage) {
    tempDetailItems.push({
      label: 'Reason for rejection',
      values: [appointment.rejectionMessage],
      type: 'text',
    });
  }

  if (appointment.tripCity) {
    tempDetailItems.push({
      label: 'Appointment City',
      values: [appointment.tripCity],
      type: 'text',
    });
  }

  if (user.firstName) {
    tempDetailItems.push({
      label: 'Your Name',
      values: [`${user.firstName} ${user.lastName}`],
      type: 'text',
    });
  }

  if (user.email) {
    tempDetailItems.push({
      label: 'Email',
      values: [user.email],
      type: 'text',
    });
  }

  if (user.phoneNumber) {
    tempDetailItems.push({
      label: 'Phone Number',
      values: [user.phoneNumber],
      type: 'text',
    });
  }

  if (appointment.tattooLocation) {
    tempDetailItems.push({
      label: 'Tattoo Area',
      values: [appointment.tattooLocation],
      type: 'text',
    });
  }

  if (appointment.tattooSize) {
    let size = appointment.tattooSize;
    const sizeItem = tempSizeOptions?.find(
      (option: any) => option.value === appointment.tattooSize,
    );
    if (sizeItem) {
      size = sizeItem.name;
    }

    tempDetailItems.push({
      label: 'Tattoo Size',
      values: [size],
      type: 'text',
    });
  }

  if (appointment.tattooColor) {
    tempDetailItems.push({
      label: 'Tattoo Color',
      values: [appointment.tattooColor],
      type: 'text',
    });
  }

  if (appointment.tattooDetails) {
    tempDetailItems.push({
      label: 'Tattoo Description',
      values: [appointment.tattooDetails],
      type: 'text',
    });
  }

  if (appointment.tattooInspiration) {
    tempDetailItems.push({
      label: 'Inspiration',
      values: [appointment.tattooInspiration],
      type: 'text',
    });
  }

  if (appointment.prefTattooStyle) {
    tempDetailItems.push({
      label: 'Preferred Tattoo Styles',
      values: [appointment.prefTattooStyle],
      type: 'text',
    });
  }

  if (appointment.budget) {
    tempDetailItems.push({
      label: 'Budget',
      values: [`$${appointment.budget}`],
      type: 'text',
    });
  }

  if (appointment.skinTone) {
    tempDetailItems.push({
      label: 'Skin Tone',
      values: [appointment.skinTone],
      type: 'text',
    });
  }

  if (appointment.refImgsId) {
    tempDetailItems.push({
      label: 'Uploaded Photos',
      values: appointment.refImgsId,
      type: 'image',
    });
  }

  if (appointment.areaImgsId) {
    tempDetailItems.push({
      label: 'Uploaded Photos',
      values: appointment.areaImgsId,
      type: 'image',
    });
  }

  return tempDetailItems;
}

export function getServicePriceText(price: number) {
  const text = `Pricing starts at $${price?.toLocaleString(
    'en-US',
  )} and is dependent on your selections at the shop.`;
  return text;
}

export function getFullPriceText(
  appointment: Pick<
    Appointment,
    | 'priceType'
    | 'minPrice'
    | 'maxPrice'
    | 'hourlyPrice'
    | 'price'
    | 'depositPrice'
    | 'appointmentType'
    | 'serviceCategory'
  >,
) {
  const {
    minPrice,
    maxPrice,
    hourlyPrice,
    price,
    depositPrice,
    serviceCategory,
    appointmentType,
    priceType,
  } = appointment;

  if (appointmentType === 'CONSULTATION') return 'Free consultation.';
  if (serviceCategory !== 'TATTOO' && priceType === 'FIXED' && price)
    return getServicePriceText(price);

  const depositText = depositPrice
    ? ` $${depositPrice.toLocaleString(
        'en-US',
      )} deposit to secure appointment. Deposit will be deducted from the total at your appointment checkout.`
    : '';

  switch (priceType) {
    case 'RANGE':
      return minPrice === 0 && maxPrice === 0
        ? `No price quoted.${depositText}`
        : `$${minPrice?.toLocaleString('en-US')} - $${maxPrice?.toLocaleString(
            'en-US',
          )} price range.${depositText}`;

    case 'HOURLY':
      return `$${hourlyPrice?.toLocaleString('en-US')} per hour.${depositText}`;

    case 'FIXED':
      return price
        ? `$${price?.toLocaleString('en-US')} for appointment.${depositText}`
        : `Pricing varies${depositText}`;

    default:
      return '';
  }
}

export function isAppointmentDepositPaid(
  appointment: (Appointment | Appointments) | null | undefined,
) {
  return (
    !!appointment?.depositPrice &&
    (!!appointment?.confirmedOn || !!appointment?.depositPaidOn) &&
    appointment?.paymentStatus === 'SUCCESS'
  );
}

export function getAppointmentPriceDetails(
  appointment: Appointment | Appointments,
) {
  const depositPaid = isAppointmentDepositPaid(appointment);
  const depositCents = (appointment.depositPrice || 0) * 100;
  const appointmentPriceCents = depositPaid
    ? appointment.price! * 100.0 - depositCents
    : appointment.price! * 100.0;

  return {
    depositPaid,
    appointmentPriceCents,
    depositCents,
  };
}

export function getAppointmentStatus(
  appointment: Pick<Appointment, 'status' | 'previousStatus'> | undefined,
) {
  return appointment?.status === 'ARCHIVED'
    ? appointment?.previousStatus || 'ARCHIVED'
    : appointment?.status;
}

export function getBooleanStringText(value: string) {
  return value === 'true' ? 'Yes' : 'No';
}
