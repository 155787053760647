import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';

export default function NotFound() {
  return (
    <Box
      style={{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar title="Not Found" subtitle="Page not found" />
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
        }}>
        <Typography variant="h1" sx={{ textAlign: 'center' }}>
          The page your looking for was not found.
        </Typography>
        <Button
          component={Link}
          to="/booking"
          className="primary"
          variant="contained"
          sx={{ maxWidth: 300 }}>
          Find your booking
        </Button>
      </Box>
      <PorterFooter />
    </Box>
  );
}
