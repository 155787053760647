import * as React from 'react';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Employee, FormValues, FullIntakeQuestion } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  employee: Employee | undefined;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  intakeQuestion?: FullIntakeQuestion;
};

function Budget({
  formValues,
  employee,
  setFormValues,
  nextStep,
  prevStep,
  intakeQuestion,
}: Props) {
  const [value, setValue] = React.useState<number | null>(null);

  const validationSchema = yup.object({
    budget: yup
      .number()
      .min(
        employee?.minAppointmentPrice || 1,
        `${
          employee?.displayName ||
          `${employee?.user.firstName} ${employee?.user.lastName}`
        } has a minimum of $${employee?.minAppointmentPrice.toFixed(2)}`,
      )
      .required('Please enter a budget'),
  });

  React.useEffect(() => {
    if (formValues.budget) {
      setValue(formValues.budget);
    }
  }, [formValues.budget]);

  const questionValues = {
    header:
      intakeQuestion?.customQuestionTitle ||
      "What's your approximate budget for this tattoo?",
    subHeader:
      intakeQuestion?.customQuestionSubheader ||
      "After your request has been reviewed, you'll receive an estimated quote for your tattoo",
  };

  const formik = useFormik({
    initialValues: {
      budget: formValues.budget,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('budget', JSON.stringify(values.budget));
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="budget">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        <NumericFormat
          placeholder="Budget"
          name="budget"
          thousandSeparator=","
          allowNegative={false}
          customInput={TextField}
          decimalScale={2}
          value={value}
          prefix="$"
          onValueChange={(values: any) => {
            formik.setFieldValue('budget', parseInt(values.value, 10));
          }}
        />
        {employee?.minAppointmentPrice && employee?.minAppointmentPrice > 0 ? (
          <FormHelperText style={{ textAlign: 'center' }}>
            {employee?.displayName ||
              `${employee?.user.firstName} ${employee?.user.lastName}`}{' '}
            has a minimum of ${employee?.minAppointmentPrice.toFixed(2)}
          </FormHelperText>
        ) : null}
        {employee?.employeeId === 'clvrbezzo002pxnjxgs9yyz91' && (
          <FormHelperText style={{ textAlign: 'center' }}>
            My minimum rate is $180, but if you&apos;re getting a small tattoo
            (under 2x2 inches), you can get a second one and do both for $180.
          </FormHelperText>
        )}
        {employee?.employeeId === 'clmi13bfw0002s60uj3ddtgod' && (
          <FormHelperText style={{ textAlign: 'center' }}>
            I charge $800 - $900 for a half day session and $1600 - $1800 for a
            full day session. Bigger projects will take multiple sessions. If
            you don&apos;t have a budget or would like to know how many sessions
            your tattoo will take, write 1111 in the budget and I will tell you
            more about how many sessions it will take.
          </FormHelperText>
        )}
        {employee?.employeeId === 'clk1qet3z000ws60u2q90zd3s' && (
          <FormHelperText style={{ textAlign: 'center' }}>
            We currently offer two options while booking a tattoo appointment
            with Eddie, both of which are full day sessions consisting of 4-6
            hours of tattooing (he can complete very large pieces within that
            timeframe). Refer to the options description on{' '}
            <a
              href="https://theblackmarkerstudio.com/"
              target="_blank"
              rel="noreferrer">
              his website
            </a>
            . The option decision is made during the consult a couple weeks out
            from the appointment date.
          </FormHelperText>
        )}
        {formik.touched.budget && formik.errors.budget && (
          <FormHelperText className="error">
            {formik.errors.budget}
          </FormHelperText>
        )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Budget;
