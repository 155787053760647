import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js/types/stripe-js';
import { Typography } from '@mui/material';
import { CheckoutSession } from '../../../types/checkout';
import SubmitButton from './SubmitButton';

interface Props {
  checkoutSession: CheckoutSession;
  onSubmit: (
    confirmPayment?: () => Promise<PaymentIntentResult>,
  ) => void | Promise<void>;
  isDisabled?: boolean;
}

export default function NewPaymentCheckoutForm({
  checkoutSession,
  onSubmit,
  isDisabled,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const { totalPriceCents = 0 } = checkoutSession || {};

  const handleSubmit = async () => {
    if (!elements || !stripe) return;
    const returnUrl = new URL(`https://${window.location.hostname}/checkout`);
    if (checkoutSession.appointment)
      returnUrl.searchParams.set(
        'appointment',
        checkoutSession.appointment.appointmentId,
      );
    if (checkoutSession.order)
      returnUrl.searchParams.set('order', checkoutSession.order.orderId);
    await onSubmit(async () => {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl.toString(),
        },
        redirect: 'if_required',
      });
      return result;
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="footer-sticky">
        <SubmitButton disabled={isDisabled} onSubmit={handleSubmit}>
          Pay ${((totalPriceCents || 0) / 100)?.toFixed(2)}
        </SubmitButton>
        <Typography variant="body2" className="center">
          Powered by Porter
        </Typography>
      </div>
    </form>
  );
}
