/* eslint-disable no-nested-ternary */
import { Stack, Typography, useTheme } from '@mui/material';
import { formatDollarAmount } from '../../utils/global';

type Props = {
  label: string;
  amount?: number;
  text?: string;
  subtract?: boolean;
  isTotal?: boolean;
};

function PaymentReceiptLineItem({
  label,
  amount,
  text,
  subtract = false,
  isTotal,
}: Props) {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Typography variant={isTotal ? 'body1' : 'body2'} fontWeight={600}>
        {label}
      </Typography>
      <Typography
        variant={isTotal ? 'body1' : 'body2'}
        color={
          isTotal
            ? theme.palette.success.main
            : subtract
            ? theme.palette.primary.main
            : undefined
        }
        fontWeight={isTotal ? 600 : 400}>
        {text || `${subtract ? '-' : ''}${formatDollarAmount(amount)}`}
      </Typography>
    </Stack>
  );
}

export default PaymentReceiptLineItem;
