import { useQuery } from '@tanstack/react-query';
import { getPaymentReceipt } from '../../services/payments';
import useUser from '../global/useUser';

function useGetPaymentReceipt({
  paymentId,
  enabled = true,
}: {
  paymentId: string | null | undefined;
  enabled?: boolean;
}) {
  const { user } = useUser();
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['payment-receipt', paymentId, user?.userId],
    queryFn: async () => {
      const result = await getPaymentReceipt({
        paymentId: paymentId!,
      });
      return result;
    },
    enabled: !!paymentId && !!user?.userId && enabled,
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
}

export default useGetPaymentReceipt;
