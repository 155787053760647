import { QueryClient, keepPreviousData } from '@tanstack/react-query';

// Create a client
export default new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (1000 * 30),
      retry: false,
      placeholderData: keepPreviousData,
    },
  },
});
