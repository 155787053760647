export enum FormQuestion {
  NAME = 'NAME',
  TATTOO_SERVICE_TYPE = 'TATTOO_SERVICE_TYPE',
  FIRST_TATTOO = 'FIRST_TATTOO',
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  COVER_UP = 'COVER_UP',
  ONGOING_PROJECT = 'ONGOING_PROJECT',
  TATTOO_LOCATION = 'TATTOO_LOCATION',
  TATTOO_SIZE = 'TATTOO_SIZE',
  TATTOO_COLOR = 'TATTOO_COLOR',
  TATTOO_DETAILS = 'TATTOO_DETAILS',
  TATTOO_INSPIRATION = 'TATTOO_INSPIRATION',
  TATTOO_STYLES = 'TATTOO_STYLES',
  BUDGET = 'BUDGET',
  FINAL_DETAILS = 'FINAL_DETAILS',
  SKIN_TONE = 'SKIN_TONE',
  GENERAL_AVAILABILITY = 'GENERAL_AVAILABILITY',
  MEDICAL_HISTORY = 'MEDICAL_HISTORY',
  REFERENCE_IMAGES = 'REFERENCE_IMAGES',
  AREA_IMAGES = 'AREA_IMAGES',
  REVIEW = 'REVIEW',
  SURROUNDING_TATTOOS = 'SURROUNDING_TATTOOS',
  EPHEMERAL = 'EPHEMERAL',
}

export const FORM_STEPS: Partial<Record<FormQuestion, number>> = {
  [FormQuestion.NAME]: 1,
  [FormQuestion.TATTOO_SERVICE_TYPE]: 2,
  [FormQuestion.FIRST_TATTOO]: 3,
  [FormQuestion.NEW_CUSTOMER]: 4,
  [FormQuestion.COVER_UP]: 5,
  [FormQuestion.ONGOING_PROJECT]: 6,
  [FormQuestion.SURROUNDING_TATTOOS]: 7,
  [FormQuestion.EPHEMERAL]: 8,
  [FormQuestion.TATTOO_LOCATION]: 9,
  [FormQuestion.TATTOO_SIZE]: 10,
  [FormQuestion.TATTOO_COLOR]: 11,
  [FormQuestion.TATTOO_DETAILS]: 12,
  [FormQuestion.TATTOO_INSPIRATION]: 13,
  [FormQuestion.TATTOO_STYLES]: 14,
  [FormQuestion.BUDGET]: 15,
  [FormQuestion.FINAL_DETAILS]: 16,
  [FormQuestion.SKIN_TONE]: 17,
  [FormQuestion.GENERAL_AVAILABILITY]: 18,
  [FormQuestion.MEDICAL_HISTORY]: 19,
  [FormQuestion.REFERENCE_IMAGES]: 20,
  [FormQuestion.AREA_IMAGES]: 21,
  [FormQuestion.REVIEW]: 22,
};
