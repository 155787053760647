import { useQuery } from '@tanstack/react-query';
import { getCheckoutSession } from '../../services/payments';
import { CheckoutSession } from '../../types/checkout';
import useUser from '../global/useUser';

function useCheckoutSession({
  appointmentId,
  orderId,
  paymentId,
  tipAmountCents,
  discountCode,
  onSuccess,
  enabled = true,
}: {
  appointmentId?: string;
  orderId?: string;
  paymentId?: string;
  tipAmountCents: number;
  discountCode?: string | null;
  onSuccess?: (checkoutSession: CheckoutSession) => void;
  enabled?: boolean;
}) {
  const { user } = useUser();
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'checkoutSession',
      appointmentId,
      orderId,
      paymentId,
      tipAmountCents,
      discountCode,
      user?.userId,
    ],
    queryFn: async () => {
      if (!appointmentId && !orderId && !paymentId) return null;
      const result = await getCheckoutSession({
        appointmentId,
        orderId,
        paymentId,
        tipAmountCents,
        discountCode,
      });
      if (result) {
        onSuccess?.(result);
      }
      return result;
    },
    enabled: (!!appointmentId || !!orderId || !!paymentId) && enabled,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return {
    checkoutSession: data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
}

export default useCheckoutSession;
