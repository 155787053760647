/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import '../App.css';
import AppointmentPage from '../components/Booking/Flows/AppointmentPage';
import Overview from '../components/Booking/Flows/Overview';
import Login from '../components/auth/Login';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ScheduleAndConfirm from '../components/Booking/Flows/ScheduleAndConfirm';
import ConfirmScheduled from '../components/Booking/Flows/ConfirmScheduled';
import Waiver from '../components/Booking/Waiver/Waiver';
import useWaiver from '../hooks/waiver/useWaiver';
import { getAppointmentStatus } from '../utils/appointment';
import useGetAppointment from '../hooks/appointments/useGetAppointment';
import useUser from '../hooks/global/useUser';
import useGetBusinessById from '../hooks/businesses/useGetBusinessById';
import useGetUserAppointments from '../hooks/appointments/useGetUserAppointments';
import useGetEmployee from '../hooks/employees/useGetEmployee';
import { convertSnakeToTitleCase } from '../utils/global';
import { signOut } from '../utils/auth';

export default function BookingPage() {
  const { appointmentId } = useParams();

  const { user, isLoading: isAuthLoading } = useUser();
  const {
    data,
    isLoading: isLoadingAppointment,
    isPlaceholderData,
    isError: error,
  } = useGetAppointment(appointmentId || null);
  const appointment =
    !isPlaceholderData && data && data.appointmentId ? data : undefined;
  const generalRequest =
    !isPlaceholderData && data && data.requestId ? data : undefined;
  const businessId = appointment?.businessId || generalRequest?.businessId;
  const {
    business: tempBusiness,
    isLoading: isLoadingBusiness,
    isPlaceholderData: isBusinessPlaceholderData,
  } = useGetBusinessById(businessId);
  const {
    employee: tempEmployee,
    isPlaceholderData: isEmployeePlaceholderData,
    isLoading: isLoadingEmployee,
    isError: isEmployeeError,
  } = useGetEmployee({
    employeeId: appointment?.employeeId,
    enabled: !!appointment,
  });

  const business =
    !isBusinessPlaceholderData && tempBusiness ? tempBusiness : undefined;
  const employee =
    !isEmployeePlaceholderData && tempEmployee ? tempEmployee : undefined;

  const loading =
    (!!appointmentId && isLoadingAppointment) ||
    (!!businessId && isLoadingBusiness) ||
    (!!appointment?.employeeId && isLoadingEmployee);

  const { data: appointmentsList } = useGetUserAppointments({
    enabled: !appointmentId,
  });

  const { needsWaiver } = useWaiver({ appointment });

  const status = getAppointmentStatus(appointment);
  const userMatch =
    user?.userId === (appointment || generalRequest)?.customerId ||
    user?.role === 'ADMIN';
  const aptDate = appointment?.startDateTime
    ? new Date(appointment.startDateTime)
    : undefined;

  React.useEffect(() => {
    if (user) {
      const appointmentIdToUse =
        appointment?.appointmentId || generalRequest?.requestId;
      if (appointmentIdToUse) {
        window._cio.page('Booking', {
          appointmentId: appointmentIdToUse,
        });
      }
    }
  }, [appointment?.appointmentId, generalRequest?.requestId, user]);

  const isConsultation = appointment?.appointmentType === 'CONSULTATION';

  switch (true) {
    // Everything is loading
    case loading || isAuthLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !isAuthLoading && !user:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Login" subtitle="Your Appointment" />
          <Login />
          <PorterFooter />
        </Box>
      );

    // User is authenticated, no appointment ID on page, so appointment list was loaded
    case user && appointmentsList && appointmentsList.length > 0:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Your Bookings" />
          <div className="appointment-list-container">
            <Typography variant="h1" style={{ marginBottom: 20 }}>
              Your Bookings
            </Typography>
            {appointmentsList?.map(appointmentRow => (
              <div
                className="appointment-link-box"
                key={appointmentRow.appointmentId}>
                <Typography variant="body1">
                  Artist: {appointmentRow.employee?.displayName}
                </Typography>
                <Typography variant="body1">
                  Appointment Status:{' '}
                  {convertSnakeToTitleCase(
                    getAppointmentStatus(appointmentRow) || '',
                  )}
                </Typography>
                <Typography variant="body1">
                  Tattoo Location: {appointmentRow.tattooLocation || 'N/A'}
                </Typography>
                <Button
                  variant="contained"
                  href={`/booking/${appointmentRow.appointmentId}`}>
                  View Appointment
                </Button>
              </div>
            ))}
          </div>
          <PorterFooter />
        </Box>
      );

    // User authed, everything else is loading
    case user &&
      !(user && (employee || business) && (appointment || generalRequest)) &&
      !isEmployeeError &&
      !error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Everything is loaded. Appointment status is undefined
    case user && appointment && !status:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Could not locate your booking. Try again or reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. User does not match customer ID of the booking they're trying to access
    case user && (appointment || generalRequest) && !userMatch:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              The phone number you logged in with does not match the user for
              this booking. Log out, and then enter the phone number you used to
              create this booking to access this page.
            </Typography>
            <Button
              variant="contained"
              className="next done"
              onClick={signOut}
              id="sign-out">
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. Employee just initiated checkout session
    case user &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      appointment.checkoutSessionActive &&
      !appointment.checkoutSessionCompleted &&
      appointment.paymentMethod === 'CREDIT' &&
      userMatch &&
      user &&
      !isEmployeeError:
      return (
        <Navigate to={`/checkout?appointment=${appointment.appointmentId}`} />
      );

    // Everything is loaded. Employee just initiated checkout session
    case user &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      appointment.checkoutSessionActive &&
      !appointment.checkoutSessionCompleted &&
      appointment.paymentMethod !== 'CREDIT' &&
      userMatch &&
      user &&
      !isEmployeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Checkout" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Checkout is active with a different payment method. Business must
              cancel the current checkout session, and initiate a new credit
              link checkout.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Checkout session is completed
    case user &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      !appointment.checkoutSessionActive &&
      appointment.checkoutSessionCompleted &&
      userMatch &&
      user &&
      !isEmployeeError:
      return employee && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Your Appointment"
          />
          <Overview
            appointment={appointment!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was scheduled by employee, needs to be confirmed by user
    case user &&
      appointment &&
      status === 'SCHEDULED' &&
      !isConsultation &&
      aptDate &&
      userMatch &&
      !isEmployeeError:
      // If employee is loaded, show flow JustConfirm.tsx. Otherwise loading element.
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Schedule Appointment"
          />
          <ConfirmScheduled
            appointment={appointment!}
            employee={employee}
            business={business}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was accepted by employee, user needs to schedule and confirm
    case user &&
      appointment &&
      status === 'ACCEPTED' &&
      user &&
      userMatch &&
      !isEmployeeError:
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Schedule Appointment"
          />
          <ScheduleAndConfirm
            appointment={appointment!}
            employee={employee}
            business={business}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment is confirmed. Display appointment details.
    case user &&
      appointment &&
      (status === 'CONFIRMED' || isConsultation) &&
      userMatch &&
      user &&
      !isEmployeeError:
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle={`Your ${isConsultation ? 'Consultation' : 'Appointment'}`}
          />
          {needsWaiver ? (
            <Box
              style={{
                flex: '1 1 0',
                marginTop: 63,
                paddingBottom: 165,
              }}>
              <Waiver
                type="appointment"
                appointment={appointment}
                business={business}
              />
            </Box>
          ) : (
            <AppointmentPage
              appointment={appointment!}
              employee={employee}
              business={business}
            />
          )}
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was requested, rejected, or canceled
    case user &&
      appointment &&
      (status === 'REQUESTED' ||
        status === 'REJECTED' ||
        status === 'CANCELED' ||
        status === 'ARCHIVED') &&
      userMatch &&
      !isEmployeeError:
      return employee && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Your Appointment"
          />
          <Overview
            appointment={appointment!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment is a general request to a shop
    case user && generalRequest && userMatch && !isEmployeeError:
      return business && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title={business.name} subtitle="Your Appointment" />
          <Overview
            appointment={generalRequest!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    case error || isEmployeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              padding: 20,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above. You may be logged in with
              a different account than the one you used to create the booking.
              Try logging out, and make sure to log back in with the same phone
              number you used to create the booking.
            </Typography>
            <Button
              variant="contained"
              className="next done"
              onClick={signOut}
              id="sign-out">
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    default:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );
  }
}
