import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getEmployeeOnschedResource } from '../../services/employee';
import { TypeOnSchedResponse } from '../../types/onsched';

type Options = Omit<
  UndefinedInitialDataOptions<
    TypeOnSchedResponse.Resource | null,
    Error,
    TypeOnSchedResponse.Resource | null,
    string[]
  >,
  'queryKey' | 'queryFn'
>;

function useOnschedResource(employeeId: string, options?: Options) {
  const enabled = options?.enabled ?? true;
  return useQuery({
    queryKey: ['employee-onsched-resource', employeeId],
    queryFn: async () => {
      if (!employeeId) return null;
      const response = await getEmployeeOnschedResource(employeeId);
      return response;
    },
    enabled: !!employeeId && !!enabled,
    staleTime: 10_000,
    gcTime: 20_000,
    ...options,
  });
}

export default useOnschedResource;
