/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress, useTheme } from '@mui/material';
import { addMinutes, isSameMinute } from 'date-fns';
import { Appointment, Employee } from '../../../interfaces';
import PaymentTable from './PaymentTable';
import CheckoutForm from './CheckoutForm';
import {
  confirmAppointment,
  reschedule,
  reserveAppointment,
} from '../../../services/appointment';
import STRIPE_PK from '../../../stripe';
import LoadingModal from '../../Global/LoadingModal';
import BottomActionBar from '../../Global/BottomActionBar';
import useDepositCheckoutSession from '../../../hooks/payments/useDepositCheckoutSession';

interface Props {
  appointment: Appointment;
  status: 'schedule' | 'reschedule';
  startDateTime: Date;
  employee: Employee;
  isBack?: boolean;
  backAction?: () => void;
}

// TODO - update with stripe actual key
const stripePromise = loadStripe(STRIPE_PK);

export default function PaymentSection({
  appointment,
  status,
  startDateTime,
  isBack,
  backAction,
  employee: _employee,
}: Props) {
  const theme = useTheme();
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitError, setSubmitError] = React.useState(false);
  const [inAppointmentExpired] = React.useState(false);
  const [tempOnschedId, setTempOnschedId] = React.useState<string>();
  const [modalOpen, setModalOpen] = React.useState(false);

  const { checkoutSession, isFetching, isLoading, isError } =
    useDepositCheckoutSession({
      appointment,
      businessId: appointment.businessId,
      status,
    });
  const {
    isPaymentRequired,
    paymentIntent,
    depositCents = 0,
    salesTaxCents = 0,
    serviceFeeCents = 0,
  } = checkoutSession || {};

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Confirming appointment...';

  const scheduleAndConfirm = async () => {
    setSubmitting(true);
    setModalOpen(true);
    setSubmitError(false);
    setErrorMessage('');

    if (isPaymentRequired) {
      setSubmitting(false);
      setModalOpen(false);
      setSubmitError(true);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please refresh the page and try again.',
      );
      return;
    }

    // If appointment ID or duration don't exist, set and display error message. Don't continue submit function
    if (!appointment.appointmentId || !appointment.appointmentLengthInMinutes) {
      setSubmitError(true);
      setModalOpen(false);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please refresh the page and try again.',
      );
      setSubmitting(false);
      return;
    }

    // If onschedID does not exist, or the initial appointment has expired, reserve the appointment in Onsched (appointment is accepted and user has not clicked confirm button yet)
    if (
      (!appointment.onschedId &&
        !tempOnschedId &&
        appointment.appointmentType !== 'WALK_IN') ||
      inAppointmentExpired ||
      (appointment.startDateTime &&
        !isSameMinute(startDateTime, new Date(appointment.startDateTime)))
    ) {
      // Set end date time based on start time and duration
      const endDateTime = addMinutes(
        startDateTime,
        appointment.appointmentLengthInMinutes,
      );

      // Reserve appointment in Onsched
      const tempAppointment = await reserveAppointment(
        startDateTime,
        endDateTime,
        appointment.appointmentId,
      ).catch(error => {
        const tempErrorMessage =
          error.error ||
          'Your appointment could not be scheduled. Please choose a new date and time and try again.';
        setSubmitError(true);
        setModalOpen(false);
        setSubmitting(false);
        setErrorMessage(tempErrorMessage);
      });

      if (!tempAppointment) {
        return;
      }
      setTempOnschedId(tempAppointment.data.result.onschedId);
    }

    // Once onsched ID exists, CONFIRM the appointment in our database and update Onsched to BK status if it's a new appointment.
    const confirmResponse = await confirmAppointment(
      appointment.appointmentId,
    ).catch(error => {
      const tempErrorMessage =
        error.error ||
        'Your appointment could not be scheduled. Please choose a new date and time and try again.';
      setSubmitError(true);
      setModalOpen(false);
      setSubmitting(false);
      setErrorMessage(tempErrorMessage);
    });

    // If everything went through, refresh the booking page to pull new data
    if (confirmResponse) {
      window._cio.track('Schedule & Confirm', {
        appointment: appointment.appointmentId,
      });

      window.location.replace(`/booking/${appointment.appointmentId}`);
    }
  };

  const rescheduleAndConfirm = async () => {
    setSubmitting(true);
    setModalOpen(true);

    if (isPaymentRequired) {
      setSubmitting(false);
      setModalOpen(false);
      setSubmitError(true);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please refresh the page and try again.',
      );
      return;
    }

    // If appointment ID or duration don't exist, set and display error message. Don't continue submit function
    if (!appointment.appointmentId || !appointment.appointmentLengthInMinutes) {
      setSubmitError(true);
      setModalOpen(false);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. Please refresh the page and try again.',
      );
      setSubmitting(false);
      return;
    }

    // Set end date time based on start time and duration
    const endDateTime = addMinutes(
      startDateTime,
      appointment.appointmentLengthInMinutes,
    );

    await reschedule(startDateTime, endDateTime, appointment.appointmentId)
      .then(() => {
        window._cio.track('Reschedule Appointment', {
          appointment: appointment.appointmentId,
        });

        window.location.replace(`/booking/${appointment.appointmentId}`);
      })
      .catch(() => {
        setSubmitError(true);
        setModalOpen(false);
        setErrorMessage(
          'There was an error re-scheduling your appointment. Please try again or reach out to our support team for assistance',
        );
        setSubmitting(false);
      });
  };

  // Set up stripe appearance settings
  const appearance = {
    labels: 'floating' as 'floating',
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorDanger: theme.palette.primary.main,
      colorBackground: '#ffffff',
      colorText: theme.palette.darkGrey.main,
      fontFamily: 'Montserrat',
      borderRadius: '5px',
      spacingUnit: '4px',
      fontSizeBase: '16px',
      fontWeightBold: '700',
      fontWeightLight: '400',
      colorTextPlaceholder: theme.palette.outlineGrey.main,
    },
    rules: {
      '.Label': {
        color: theme.palette.outlineGrey.main,
      },
      '.Input': {
        borderColor: theme.palette.outlineGrey.main,
      },
      '.Input:focus': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
      '.Input--focused': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
    },
  };

  const fonts = [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400' },
  ];

  const options = {
    clientSecret: paymentIntent?.client_secret || '',
    appearance,
    fonts,
  };

  if (isError) {
    return (
      <div className="payment-section">
        <Typography variant="body1" className="error">
          An error occurred loading the details. Please refresh the page and try
          again.
        </Typography>
      </div>
    );
  }

  return (
    <div className="payment-section">
      {isLoading && (
        <div className="loading-spinner">
          <CircularProgress
            sx={{
              marginTop: 5,
            }}
          />
        </div>
      )}

      {isPaymentRequired ? (
        <PaymentTable
          depositAmtCents={depositCents}
          serviceFeeCents={serviceFeeCents}
          salesTaxCents={salesTaxCents}
          status={status}
        />
      ) : null}

      {isPaymentRequired && options.clientSecret === '' ? (
        <Typography align="center">Loading...</Typography>
      ) : null}

      {isPaymentRequired && options.clientSecret !== '' ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            appointment={appointment}
            startDateTime={startDateTime}
            status={status}
            inAppointmentExpired={inAppointmentExpired}
          />
        </Elements>
      ) : null}

      {!isPaymentRequired && !submitting && status === 'schedule' ? (
        isBack ? (
          <BottomActionBar
            primaryText="Confirm"
            primaryAction={scheduleAndConfirm}
            primaryDisabled={isFetching}
            secondaryText="Back"
            secondaryAction={backAction}
          />
        ) : (
          <BottomActionBar
            primaryText="Confirm"
            primaryDisabled={isFetching}
            primaryAction={scheduleAndConfirm}
          />
        )
      ) : null}

      {!isPaymentRequired && !submitting && status === 'reschedule' ? (
        isBack ? (
          <BottomActionBar
            primaryText="Confirm"
            primaryAction={rescheduleAndConfirm}
            secondaryText="Back"
            secondaryAction={backAction}
            primaryDisabled={isFetching}
          />
        ) : (
          <BottomActionBar
            primaryText="Confirm"
            primaryDisabled={isFetching}
            primaryAction={rescheduleAndConfirm}
          />
        )
      ) : null}

      {!isPaymentRequired && submitting && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={modalOpen}
        />
      )}

      {submitError ? (
        <Typography variant="body1">{errorMessage}</Typography>
      ) : null}
    </div>
  );
}
